'use client'
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SectionTitle from "@/components/SectionTitle";

import ImageParallax from "@/components/ImageParallax";


const MyComponent = ({data}) => {

    return (
        <StyledComponent className={"pt-100 pb-100"} id={'homeTransaction'} >
            <ImageParallax src={data?.images?.list?.[0]?.cdn_path} alt={'home-cta'}/>
            <Container>
                <SectionTitle text={data?.section_data?.section_title} color={'#FFF'} border={'#FFF'}/>
                <Row className={'overview-row'}>
                    <Col>
                        <h2>{data?.section_data?.subtitle}</h2>
                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );

};

const StyledComponent = styled.section`
    position: relative;
    height: 800px;
    overflow: hidden;
    will-change: transform;
    h2{
        font-size: 60px;
        line-height: 70px;
        color: #FFF;
        font-weight: 800;
    }
    
    
    @media(max-width: 767px){
        height: 100vh;
        .overview-row{
            flex-direction: column;
        }
        h2{
            font-size: 40px;
            line-height: 48px;
        }
        .btn{
            margin-top: 40px;
        }
    }
`;

export default MyComponent;
