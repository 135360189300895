import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Shanta Equity\\components\\home\\Banner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Shanta Equity\\components\\home\\Counts.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Shanta Equity\\components\\home\\CTA.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Shanta Equity\\components\\home\\Overview.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Shanta Equity\\components\\home\\PortfolioManagement.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Shanta Equity\\components\\home\\WhyShanta.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Shanta Equity\\components\\home\\Zigzag.jsx");
