'use client'
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import Title from "@/components/Title";
import Description from "@/components/Description";
import {Img} from "@/components/Img";
import {useEffect, useState} from "react";



const MyComponent = ({data}) => {

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        // Set initial window width
        setWindowWidth(window.innerWidth);

        // Update window width on resize
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <StyledComponent className={"why-shanta pt-150 pb-150"} id={'whyShanta'}>
            {
                typeof window !== 'undefined' && windowWidth > 767 ?
                    <Img src={data?.images?.list?.[0]?.cdn_path} alt={'home-cta'}/>:
                    <Img src={data?.images?.list?.[1]?.cdn_path} alt={'home-cta'}/>
            }
            <Container>
                <Row className={'overview-row'}>
                    <Col md={4}>
                        <Title text={data?.section_data?.subtitle}
                        fontSize={'40'} lineHeight={'50'} color={'#FFF'} />

                    </Col>
                    <Col md={{span:7, offset:1}}>
                        <Description
                            text={data?.section_data?.description}
                        color={'#FFF'}
                        />
                    </Col>
                </Row>
                <Row>
                    {
                        data?.posts?.list && data?.posts?.list.length>0
                        && data?.posts?.list.map((element,index)=>{
                            return(
                            <Col md={4} key={index}>
                                <div className="why-shanta__single">
                                    <div className="why-shanta__single__wrapper">
                                        <img src={element?.images?.[0]?.cdn_path} alt=""/>
                                        <p>{reactHtmlParser(element?.data?.subtitle)}</p>
                                    </div>
                                </div>
                            </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );

};

const StyledComponent = styled.section`
    position: relative;
    overflow: hidden;
    will-change: transform;
    .overview-row {
        margin-bottom: 60px;
    }

    .why-shanta__single{
        
        backdrop-filter: blur(15px);
        background-color: rgba(255, 255, 255, 0.35);
        position: relative;
        height: 100%;
        border-radius: 5px;
        &__wrapper{
            padding: 40px 30px;  
            position: relative;
            height: 100%;
            border-radius: 5px;
            
            &:hover{
                    &:before{
                        width: 100%;
                        transition-delay: 300ms;
                    }
                    &:after{
                        height: 100%;
                        transition-delay: 450ms;
                    }
                
            }
        }
        img{
            margin-bottom: 20px;
        }
        p{
            color: #FFF;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
        }
       
        &:hover{
            &:before{
                width: 100%;
                transition-delay: 0s;
            }
            &:after{
                height: 100%;
                transition-delay: 150ms;
            }
            
        }
    }
    
    @media(max-width: 767px){
        .title{
            margin-bottom: 40px;
        }
        .why-shanta__single{
            margin-bottom: 30px;
            height: unset;
        }
    }
`;

export default MyComponent;
