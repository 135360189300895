'use client'
import styled from "styled-components";
import {Black} from "@/styles/globalStyleVars";
import {Col, Container, Row} from "react-bootstrap";
import Title from "@/components/Title";
import Button from "@/components/Button";
import Description from "@/components/Description";
import {Img} from "@/components/Img";



const MyComponent = ({bg_img,section_title,title,button_link,img,desc,imgBottom}) => {

    return (
        <StyledComponent className={" cta pt-100 pb-100"} id={'homeCTA'}>
            <Img src={bg_img} alt={'home-cta'}/>
            <Container>
                <Row className={'overview-row'}>
                    <Col md={5}>
                        {/*<SectionTitle text={section_title} color={'#FFF'} border={'rgba(255,255,255,0.2)'}/>*/}
                        <Title text={title} margin={'0 0 30px 0'} color={'#FFF'}/>
                        <Description text={desc} color={'#FFF'}/>
                        <Button text={'Contact Us'} margin={'40px 0 0 0'} background={'#978C21'}
                                color={'#FFF'}
                                hoverBackground={'#638297'}
                                src={button_link}
                        />
                    </Col>
                    <Col md={7}>
                        <div className="cta-img">
                            <div className="cta-img__top">
                                <img src={img} alt={'home-cta'}/>
                            </div>
                            <div className="cta-img__bottom">
                                <img data-speed={"clamp(1.1)"} src={imgBottom} alt={'home-cta'}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="bottom-svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="391"
                     height="395" viewBox="0 0 391 395">
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_6864" data-name="Rectangle 6864" width="391" height="395"
                                  fill="#c6c6c6"/>
                        </clipPath>
                    </defs>
                    <g id="Mask_Group_438" data-name="Mask Group 438" opacity="0.1" clip-path="url(#clip-path)">
                        <path id="path78564"
                              d="M163.2,424.827c-1.165-.277-6.881-1.266-12.7-2.2-31.094-4.978-62.069-19.9-81.362-39.192-12.686-12.686-33.084-35.251-38.892-43.024C-2.851,296.13-9.494,220.815,13.744,153.422,37.734,83.849,87.869,31.8,151.286,10.628,177.333,1.932,190.4.041,224.6.013c36.174-.03,55,2.456,65.585,8.66,22.532,13.2,35.5,39.988,31.381,64.827-4.881,29.456-27.16,47.084-59.506,47.084-8.68,0-13.65-1.023-26.926-5.542-24.9-8.476-37.172-10.655-59.234-10.519-22.228.137-34.655,2.892-52.857,11.719C80.907,136.673,49.1,183.606,35.93,244.8c-2.736,12.708-3.187,18.947-3.161,43.758.026,25.093.427,30.37,3.023,39.726,8.516,30.693,26.584,48.863,57.291,57.613,31.591,9,75.508,6.48,112.834-6.479,44.019-15.283,74.645-40.583,91.3-75.424,9.318-19.491,11.741-30.957,11.915-56.372.16-23.382-1.478-34.475-8.544-57.874-3.07-10.165-3.487-13.616-2.735-22.585,3.587-42.76,29.249-66.6,66.488-61.781,17.912,2.32,36.412,16.229,45.038,33.86,5.972,12.207,8.3,49.462,4.99,79.851-6.315,57.971-26.481,101.875-64.045,139.433-26.021,26.017-54.013,43.364-88.531,54.864-25.622,8.536-39.719,10.848-69.663,11.423-14.751.283-27.772.288-28.937.011Z"
                              transform="translate(-25.356 0.588)" fill="#c6c6c6"/>
                    </g>
                </svg>

            </div>
        </StyledComponent>
    );

};

const StyledComponent = styled.section`
    position: relative;
    overflow: hidden;
    will-change: transform;
    .bottom-svg {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    
    .title{
        @media(max-width: 768px){
            margin-top: 0;
        }
    }
    
    .col-md-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cta-img__top {
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 1;

        img {
            height: 320px;
            width: 320px;
            border-radius: 50%;
            object-fit: cover;
        }

        @media (max-width: 767px) {
            margin-top: 40px;
            img {
                height: 210px;
                width: 210px;
            }
        }
        @media (min-width: 1920px) {
            img {
                height: 400px;
                width: 400px;
            }
        }
    }

    .cta-img__bottom {
        margin-top: -100px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 0;
        right: 170px;

        img {
            height: 320px;
            width: 320px;
            border-radius: 50%;
            object-fit: cover;
        }
        @media (max-width: 991px) {
            right: 90px;
        }

        @media (max-width: 767px) {
            left: 15px;
            justify-content: flex-start;
            img {
                height: 210px;
                width: 210px;
            }
        }
        @media (min-width: 1920px) {
            img {
                height: 400px;
                width: 400px;
            }
        }
    }
    
    @media(max-width: 991px) and (min-width: 768px){
        .overview-row{
            flex-direction: column;
            .col-md-5,.col-md-7{
                flex: 0 0 100%;
                max-width: 100%;
            }
            .cta-img__bottom{
                right: 100px;
                margin-top: -120px;
                justify-content: center;
                img{
                    width: 400px;
                    height: 400px;
                }
            }
            .cta-img__top{
                margin-top: 60px;
                img{
                    width: 400px;
                    height: 400px;
                }
            }
            
        }
    }
`;

export default MyComponent;
