"use client"
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover} from '@/styles/globalStyleVars';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {Col, Container, Row} from "react-bootstrap";
import Button from "@/components/Button";
import reactHtmlParser from "react-html-parser";
import Image from 'next/image';




const Banner = ({data}) => {


    const [offset, setOffset] = useState(90);

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)
        })
    }, [data])

    const interleaveOffset = 0.5;


    // handle slider progress
    const handleProgress = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            const slideProgress = swiper.slides[i].progress;
            const innerOffset = swiper.width * interleaveOffset;
            const innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".slide-inner").style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
        }
    };

    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".slide-inner")
                .style.transition = `${speed}ms`;
        }
    };

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        // Set initial window width
        setWindowWidth(window.innerWidth);

        // Update window width on resize
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);




    return (
        <StyledBanner className={'home-banner'} offset={offset} id={'homeBanner'}>
            {
                data?.posts?.list &&
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    spaceBetween={0}
                    autoplay={{ delay: 5000 }} // Start autoplay when enabled
                    speed='1500'
                    onProgress={handleProgress}
                    onSetTransition={handleSetTransition}
                    grabCursor={false}
                    watchSlidesProgress={true}
                    mousewheelControl={true}
                    keyboardControl={true}
                    pagination={{ clickable: true }}
                    className="mySwiper"
                >
                    {
                        data?.posts?.list && data?.posts?.list.length >0
                        && data?.posts?.list.map((element,index)=>{
                            return(
                                <SwiperSlide key={index}>
                                    <div className="slide-inner">

                                        <Image
                                            src={
                                                windowWidth > 767
                                                    ? element?.images?.find((f) => f?.banner === "on")?.cdn_path
                                                    : element?.images?.find((f) => f?.thumb === "on")?.cdn_path || element?.images?.find((f) => f?.banner === "on")?.cdn_path
                                            }
                                            alt={'home banner'}
                                            fill // or use layout="responsive" and provide width/height if appropriate
                                            objectFit="cover" // or "contain" depending on your needs
                                            priority={index === 0} // To prioritize loading of the first image
                                        />
                                        <Container className={'container_info'}>
                                            <Row>
                                                <Col md={8}>
                                                    <div className="slide-inner__info">
                                                        {
                                                            index === 0 ?
                                                                <h1>{reactHtmlParser(element?.data?.banner_title)}</h1> :
                                                                <h2>{reactHtmlParser(element?.data?.banner_title)}</h2>
                                                        }
                                                        <p>{reactHtmlParser(element?.data?.description)}</p>
                                                        <Button
                                                            text={element?.data?.subtitle}
                                                            background={'#978C21'}
                                                            hoverBackground={hover}
                                                            hovercolor={'#FFF'}
                                                            arrowhoverfilter={`brightness(100%)`}
                                                            src={element?.data?.short_desc}
                                                            hoverborder={`1px solid ${hover}`}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
            }

        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  position: relative;
  overflow: hidden;
  .home-banner {
    .container {
      position: relative;
    }
  }

  .mobile-version {
    display: none;
  }

  .sweeper-nav {
    position: absolute;
    left: ${p => p.offset + 15}px;
    right: ${p => p.offset}px;
    bottom: 40px;
    z-index: 999;

    ul {
      display: flex;
    }

    @media (max-width: 767px) {
      bottom: 5%;
    }
    @media (max-width: 376px) {
      bottom: 3%;
    }
    
  }

  .banner-slider__init {
    display: flex;
    justify-content: space-between;
      

    &__slider {
      width: 28%;
      margin-right: 15px;

      .banner-slider__single {
        display: block;

        &__inner {
          padding-top: calc(240 / 370 * 100%);
          position: relative;
          border: 1px solid rgba(255, 255, 255, 0.5);
          background: rgba(0, 0, 0, 0.55);
          backdrop-filter: blur(3px);
          transition: .5s ease;

          a {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 2;
            left: 0;
            top: 0;
          }

          img {
            position: absolute;
            top: 30px;
            left: 30px;
          }
          
          @media(max-width: 767px){
            img{
              top: 50px;
            }
          }


          &__bottom {
            position: absolute;
            left: 30px;
            right: 30px;
            bottom: 20px;
            color: #FFF;
            display: flex;
            justify-content: space-between;

            p {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
            }
              
          }
            
        }

        &:hover {
          .banner-slider__single__inner {
            //background: rgba(221, 221, 221, 0.09);
            backdrop-filter: blur(7px);
          }
        }
      }
    }


    @media(max-width: 991px){
      &__slider {
        width: 65%;
        margin-right: 30px;
      }
    }
    @media (max-width: 767px) {
      display: block;
      &__slider {
        width: 65%;
      }
    }
    @media(max-width: 415px){
      &__slider {
        width: 75%;
      }
    }
  }

  .home-banner {
    .container {
      position: relative;
    }
  }

  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(0, 10px, 0);
    }
  }

  .swiper-button-prev, .swiper-button-next {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;

  }

  .slide-inner {
    position: relative;
    height: 100svh;

    .container_info {
      position: absolute;
      left: 0;
      right: 0;
      top: 60% !important;
      transform: translateY(-50%);
      z-index: 999999999999;

        h2,h1{
            color: #FFF;
            margin-bottom: 10px;
        }
        p{
            color: #FFF;
            margin-bottom: 30px;
        }
      .dc-btn {
        z-index: 999999999999;
      }

      @media (max-width: 767px) {
        top: 40% !important;
      }

      @media (max-width: 400px) {
        top: 40% !important;
      }
      
    }

    .swiper-pagination {
      //display: none !important;
    }

    .global-image {
        img{
            position: absolute;
            height: 100%;
            width: 100%;
            inset: 0px;
            object-fit: cover;
            color: transparent;
        }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      position: relative;
      overflow: hidden;

      h1,h2 {
        color: #FFF;
        @media(max-width: 767px){
          font-size: 28px;
          line-height: 32px;
        }
       
      }
        

      .dc-btn {
        margin-top: 30px;
        position: relative;
      }
      @media(max-width: 376px){
        h1{
          font-size: 26px;
        }
        h4{
          font-size: 10px;
        }
      }
    }
  }

  .swiper-slide-active {
    .slide-inner {
      &__info {
        h1,h2 {
          transform: translateY(0px);
        }

        p {
          transform: translateY(0px);
        }

        .dc-btn {
          transform: translateY(0px);
        }
      }

    }
  }

  .swiper-pagination {
    //display: none !important;
  }

  .swiper {
    position: relative;
  }

  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h2,h1 {
          font-size: 64px;
          line-height: 72px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .banner-slider__init__nav {
      display: none;

      &.mobile-version {
        margin-top: 40px;
        display: flex;
      }
    }


    .slide-inner {
      .container_info {
        transform: unset;
        bottom: 100px;
          top: unset !important;
      }

      &__info {
        position: relative;
        overflow: hidden;

        h2,h1 {
          font-size: 40px;
          line-height: 48px;
        }

        .dc-btn {
          margin-top: 30px;
        }
      }
    }

  }
  @media(max-width: 376px){
    .banner-slider__init__nav {
      display: none;

      &.mobile-version {
        margin-top: 15px;
        display: flex;
      }
    }
    .banner-slider__init__slider .banner-slider__single__inner img{
      width: 15%;
      top: 50px;
    }

  }

    .swiper-pagination{
        text-align: unset;
        left: ${p => p.offset + 15 +'px'};
        bottom: 50px;
        @media (max-width: 991px) and (min-width: 768px){
        left: ${p => p.offset + 50 +'px'};
        bottom: 30px;
    }
        
    }
    .swiper-pagination-bullet{
        background-color: #FFF;
        //outline: 1px solid #FFF;
        opacity: 1;
        width: 5px;
        height: 5px;
        transition: all 0.3s ease-in-out;
        margin-right: 15px !important;
        border-radius: 50% !important;
    }
    .swiper-pagination-bullet-active{
        position: relative;
        &:after{
            position: absolute;
            content: '';
            width: 15px;
            height: 15px;
            background: transparent;
            border: 1px solid #FFF;
            top: -5px;
            left: -5px;
            border-radius: 50% !important;
        }
    }
`;
export default Banner;

