'use client'
import styled from "styled-components";
import {Black, hover, HoverArrow, primary, whites} from "@/styles/globalStyleVars";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import SectionTitle from "@/components/SectionTitle";
import Title from "@/components/Title";
import Button from "@/components/Button";
import SubTitle from "@/components/SubTitle";
import Description from "@/components/Description";


const MyComponent = ({data}) => {
    return (
        <StyledComponent className={"pt-100 pb-100 watch-section"} id={'ourStory'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="415.902" height="425.018" viewBox="0 0 415.902 425.018">
                <path id="path78564"
                      d="M163.2,424.827c-1.165-.277-6.881-1.266-12.7-2.2-31.094-4.978-62.069-19.9-81.362-39.192-12.686-12.686-33.084-35.251-38.892-43.024C-2.851,296.13-9.494,220.815,13.744,153.422,37.734,83.849,87.869,31.8,151.286,10.628,177.333,1.932,190.4.041,224.6.013c36.174-.03,55,2.456,65.585,8.66,22.532,13.2,35.5,39.988,31.381,64.827-4.881,29.456-27.16,47.084-59.506,47.084-8.68,0-13.65-1.023-26.926-5.542-24.9-8.476-37.172-10.655-59.234-10.519-22.228.137-34.655,2.892-52.857,11.719C80.907,136.673,49.1,183.606,35.93,244.8c-2.736,12.708-3.187,18.947-3.161,43.758.026,25.093.427,30.37,3.023,39.726,8.516,30.693,26.584,48.863,57.291,57.613,31.591,9,75.508,6.48,112.834-6.479,44.019-15.283,74.645-40.583,91.3-75.424,9.318-19.491,11.741-30.957,11.915-56.372.16-23.382-1.478-34.475-8.544-57.874-3.07-10.165-3.487-13.616-2.735-22.585,3.587-42.76,29.249-66.6,66.488-61.781,17.912,2.32,36.412,16.229,45.038,33.86,5.972,12.207,8.3,49.462,4.99,79.851-6.315,57.971-26.481,101.875-64.045,139.433-26.021,26.017-54.013,43.364-88.531,54.864-25.622,8.536-39.719,10.848-69.663,11.423-14.751.283-27.772.288-28.937.011Z"
                      transform="translate(0.019 -0.012)" fill="#3c3c3b" opacity="0.03"/>
            </svg>
            <Container>
                <SectionTitle text={data?.section_data?.section_title} color={primary}/>
                <Row className={'overview-row'}>
                    <Col>
                        <Title text={data?.section_data?.subtitle} margin={'0 0 40px 0'} spancolor={primary}/>
                    </Col>
                    <Col>
                        <SubTitle text={data?.section_data?.short_desc}/>
                        <Description text={data?.section_data?.description}/>
                        <Button text={'Know More'} background={'transparent'} hoverBackground={hover}
                        color={primary} hovercolor={whites} arrowfilter={HoverArrow} margin={'30px 0 0 0'}
                                src={'/about-us'} hoverborder={`1px solid ${hover}`}
                        />
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );

};

const StyledComponent = styled.section`
    position: relative;
    background-color: #F6F6F6;
    overflow: hidden;
    will-change: transform;
    svg{
        position: absolute;
        bottom: 0;
        left: 0;
    }
    @media (max-width: 767px) {
        .overview-row {
            flex-direction: column;
        }
    }
`;

export default MyComponent;
