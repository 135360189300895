'use client'
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import { hover, text} from "@/styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import Button from "@/components/Button";

const MyComponent = ({data}) => {
    const firstThree = data?.posts?.list?.filter(f=>f?.data?.in_home === 'yes');
    const firstTwo = firstThree?.slice(0, 2);
    const isNarrowScreen = typeof window !== 'undefined' && window.innerWidth < 991 && window.innerWidth > 767;
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) {
        return null; // or a loading spinner
    }
	
	return (
        <StyledComponent className={'counts'}>
            <Container>
                <Row>
                    <Col>
                        <div className="btn">
                            <Button text={'Our Transactions'} src={'/transactions'} background={'#978C21'}/>
                        </div>
                    </Col>
                </Row>
                {
                    isNarrowScreen ?
                        <Row>
                            {
                                firstTwo.map((element,index) => {
                                    return(
                                        <Col md={6} key={index}>
                                            <div className="transaction-list-item">
                                                <h4 className={'cat'}>{element?.data?.subtitle}</h4>
                                                <img src={element?.images?.[0]?.cdn_path} alt=""/>
                                                <h4 className={'name'}>{element?.data?.title}</h4>
                                                <p className={'text'}>{reactHtmlParser(element?.data?.description)}
                                                </p>
                                                <p className={'year'}>{element?.data?.short_desc}</p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }

                        </Row> :

                        <Row>
                            {
                                firstThree?.map((element,index) => {
                                    return(
                                        <Col md={4} key={index}>
                                            <div className="transaction-list-item">
                                                <h4 className={'cat'}>{element?.data?.subtitle}</h4>
                                                <img src={element?.images?.[0]?.cdn_path} alt=""/>
                                                <h4 className={'name'}>{element?.data?.title}</h4>
                                                <p className={'text'}>{reactHtmlParser(element?.data?.description)}
                                                </p>
                                                <p className={'year'}>{element?.data?.short_desc}</p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }

                        </Row>

                }

            </Container>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    position: relative;
    background-color: transparent;
    margin-top: -300px;
    overflow-x: hidden;
    will-change: transform;
    &:after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 50%;
        background: #F6F6F6;
        z-index: -1;
        @media (max-width: 767px) {
            height: 70%;
        }
        @media (min-width: 1550px) {
            height: 50%;
        }
    }
    
    .btn{
        margin-bottom: 40px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .transaction-list-item{
        padding: 65px 30px;
        background-color: #ffffff;
        border-radius: 5px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .cat{
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            color: ${hover};
            margin-bottom: 20px;
        }
        img{
            margin-bottom: 20px;
            height: 100px;
        }
        .name{
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
            color: #3C3C3B;
            margin-bottom: 20px;
        }
        .text{
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: rgba(60,60,59,0.75);
            margin-bottom: 45px;
        }
        .year{
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: ${hover};
            position: absolute;
            bottom: 65px;
            left: 50%;
            transform: translateX(-50%);

        }
        h4,p{
            text-align: center;
        }
        @media(max-width: 767px){
            padding: 30px 15px;
            
            .year{
                bottom: 30px;
            }
        }
    }
    @media(max-width: 991px){
        margin-top: -400px;
    }

    @media (max-width: 767px) {
    .col-md-4{
      margin-bottom: 20px;
    }
    .mission-vision__box{
      padding: 20px 15px;
    }
  }
    @media (max-height: 1000px) and (max-width: 767px) {
        margin-top: -600px;
    }
    @media (max-height: 900px) and (max-width: 767px) {
        margin-top: -550px;
    }
    @media (max-height: 840px) and (max-width: 767px) {
        margin-top: -500px;
    }
    @media (max-height: 780px) and (max-width: 767px) {
        margin-top: -450px;
    }
    @media (max-height: 720px) and (max-width: 767px) {
        margin-top: -440px;
    }
    @media (max-height: 700px) and (max-width: 767px) {
        margin-top: -380px;
    }
`
export default MyComponent;