'use client'
import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Modal, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import Button from "@/components/Button";

import {Img} from "../Img";
import Title from "@/components/Title";
import {HoverArrow, primary} from "@/styles/globalStyleVars";
const AssetService = ({offset,btnSrc,img,pdTop,pdBottom,reverse,title,desc,short_desc,bgColor}) => {
    return (
        <StyledService className={`asset-seal ${pdTop} ${pdBottom} ${bgColor} watch-section`} reverse={reverse}>
            <Container className={` ${reverse ? 'reverse' : ''}`}>
                {
                    reverse==="yes" ?
                        <Row className={'reverse-row'}>
                            <Col sm={{span: 6}} className='asset-seal__text'>
                                {
                                    title &&
                                    <Title text={title} margin={'20px 0 40px 0'}/>
                                }
                                <div className={'description'}>

                                    {
                                        reactHtmlParser(desc)
                                    }

                                    <Button text={'Explore'}
                                    background={'transparent'}
                                            arrowfilter={HoverArrow}
                                            margin={'40px 0 0 0'}
                                            color={primary}
                                            hoverborder={'1px solid #638297'}
                                            src={btnSrc}
                                    />
                                </div>
                            </Col>
                            <Col sm={{span: 6}} className='asset-seal__img'>
                                <div className={`asset-seal__img__inner`}>
                                    <Img layout={'responsive'} src={img} alt=""/>
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col sm={6} className='asset-seal__img'>

                                <div className={`asset-seal__img__inner`}>
                                    <Img src={img} alt=""/>
                                </div>
                            </Col>

                            <Col sm={{span: 6}} className='asset-seal__text'>
                                {
                                    title &&
                                    <Title text={title} margin={'20px 0 40px 0'}/>
                                }
                                <div className={'description'}>

                                    {
                                        reactHtmlParser(desc)
                                    }
                                    <Button text={'Explore'}
                                            background={'transparent'}
                                            arrowfilter={HoverArrow}
                                            margin={'40px 0 0 0'}
                                            color={primary}
                                            hoverborder={'1px solid #638297'}
                                            src={btnSrc}
                                    />
                                </div>
                            </Col>
                        </Row>
                }

            </Container>

        </StyledService>
    );
};

const StyledService = styled.section`
  background-color: #F6F6F6;
    overflow: hidden;
    will-change: transform;
    &.bg-color{
        background-color: rgb(245, 245, 245);
    }
  &:last-child{
    padding-bottom: 150px;
    @media(max-width: 767px){
      padding-bottom: 80px;
    }
  }
  .container-fluid{
    // padding-right: ${props => props.offset ? props.offset : '115'}px;
    &.reverse{
      // padding-left: ${props => props.offset ? props.offset : '90'}px;

      .security-seal__text{
        padding-left: 0;
        padding-right: 70px;
      }
      
      @media(max-width: 991px){
        .reverse-row{
          flex-direction: column-reverse;
        }
      }
    }
  }
  .asset-seal__img {
    padding-right: 35px;
    .title{
      //display: none;
    }
    &__inner {
      padding-top: calc(600 / 570 * 100%);
      position: relative;
      overflow: hidden;
      min-height: 100%;
        border-radius: 10px;
        img{
            object-fit: cover;
            border-radius: 10px;
        }
    }
    @media(max-width: 991px){
      .title{
        display: block;
      }
    }
  }
  .reverse-row{
    .asset-seal__text{
      padding-right: 35px;
    }
    .asset-seal__img{
      padding-left: 35px;
    }
    @media(max-width: 991px){
      flex-direction: column-reverse;
    }
  }

  .asset-seal__text {
    p {
      color: #3C3C3B;
      :last-child{
        margin-bottom: 0px;
      }
    }
    h3{
      margin-top: 60px;
      margin-bottom: 20px;
      font-weight: 300;
      font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
      color: #2A2627;
    }
    .address{
      a{
        font-size: 16px;
        line-height: 24px;
        display: block;
        width: max-content;
      }
      .map-address{
        margin-bottom: 20px;
        p{
          margin-bottom: 0;
        }
      }
    }
    .title{
      display: block;
    }
    @media(max-width: 991px){
      .title{
        //display: none;
      }
    }
  }

  @media(min-width: 992px){
    .asset-seal__text{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      //justify-content: center;
      padding-left: 35px;
    }
  }
  @media (max-width: 991px) {
    .container-fluid{
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    .asset-seal__img {
      min-width: 100%;
      padding-left: 15px !important;
      padding-right: 15px !important;
        overflow: hidden;
    }   
    .asset-seal__text {
      min-width: 100%;
      margin-top: 60px;
      margin-left: 0;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
    @media(max-width: 767px){
        .asset-seal__text {
            margin-top: 30px;
            .title{
                margin-top: 0;
                margin-bottom: 20px;
            }
        }
    }

  @media (max-width: 991px) {
    img{
      border-radius: 0px;
    }
    .dc-btn {
      margin-top: 30px;
    }
  }
    
    .description{
        p{
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            &:not(:last-child){
                margin-bottom: 20px;
            }
            @media(min-width: 1920px){
                font-size: 18px;
                line-height: 27px;
            }
        }
        ul{
            margin-top: 40px;
           li{
               position: relative;
               padding-left: 20px;
               &:after{
                   content: '';
                   position: absolute;
                   top: 7px;
                   left: 0;
                   width: 10px;
                   height: 10px;
                   background-color: #8A1313;
                   border-radius: 50%;
               }
           } 
        }
    }
    
    @media(min-width: 1500px){
        .title{
            width: 70%;
        }
        .description{
            width: 70%;
        }
    }


`;
export default AssetService;
